<template>
  <div class="elv-reconciliation-history">
    <div class="elv-reconciliation-history__title">
      <el-skeleton v-if="loading" animated :throttle="200" class="is-skeleton">
        <template #template>
          <el-skeleton-item variant="p" />
        </template>
      </el-skeleton>
      <template v-else> {{ tableData.total }} {{ tableData.total > 1 ? 'activities' : 'activity' }} </template>
    </div>
    <div class="elv-reconciliation-history-table-container">
      <div class="elv-reconciliation-history-table-box">
        <el-table
          ref="historyTableRef"
          v-table-skeleton="{ loading: tableLoading }"
          :data="tableData.list"
          border
          height="100%"
          class="elv-reconciliation-history-table"
          header-cell-class-name="elv-reconciliation-history-table-header__cell"
          header-row-class-name="elv-reconciliation-history-table-header"
          row-class-name="elv-reconciliation-history-table-row"
          cell-class-name="elv-reconciliation-history-table-row__cell"
        >
          <el-table-column width="150" :label="t('common.dateTime')">
            <template #default="{ row }">
              <div class="elv-reconciliation-history-table-row__cell-date">
                {{
                  row?.createdAt
                    ? dayjs(row?.createdAt)
                        .tz(row?.entity?.timezone ?? 'UTC')
                        .format('YYYY/MM/DD HH:mm:ss')
                    : '-'
                }}
              </div>
            </template>
          </el-table-column>
          <el-table-column :label="t('title.securityActivity')">
            <template #default="{ row }">
              <div class="elv-reconciliation-history-table-row__cell-activity">
                {{ activityContent(row) }}
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="address" :label="t('common.member')" width="180">
            <template #default="{ row }">
              <div class="elv-reconciliation-history-table-row__cell-member">
                <VueBlockies
                  v-if="row?.user?.name"
                  style="border-radius: 50%"
                  :seed="md5UserParams(row?.user?.userId)"
                  :color="changeAvatarColor(md5UserParams(row?.user?.userId))[0]"
                  :bgcolor="changeAvatarColor(md5UserParams(row?.user?.userId))[1]"
                  :size="7"
                  :scale="4"
                  spot-color="#666"
                />
                <img v-else src="@/assets/img/member-invite-avatar.png" alt="avatar" />
                <p>{{ row?.user?.name }}</p>
              </div>
            </template>
          </el-table-column>
          <el-table-column prop="requestIp" label="IP" min-width="130" width="165" />
          <el-table-column prop="client" :label="t('common.client')" />
        </el-table>
      </div>
      <elv-pagination
        size-popper-class="elv-sources-pagination__sizes-popper"
        :limit="historyParams.limit"
        :current-page="historyParams.page"
        :total="tableData.total"
        @current-change="onChangePage"
        @size-change="onChangePageSize"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import md5 from 'js-md5'
import dayjs from 'dayjs'
import { useI18n } from 'vue-i18n'
import utc from 'dayjs/plugin/utc'
import VueBlockies from 'vue-blockies'
import { ElMessage } from 'element-plus'
import { avatarColor } from '@/config/index'
import timezone from 'dayjs/plugin/timezone'
import ReconciliationApi from '@/api/ReconciliationApi'

const props = defineProps({
  width: {
    type: String,
    default: '100%'
  }
})

dayjs.extend(utc)
dayjs.extend(timezone)
const { t } = useI18n()
const route = useRoute()
const historyTableRef = useTemplateRef('historyTableRef')
const loading = ref(false)
const tableLoading = ref(false)

const historyParams = reactive({
  page: 1,
  limit: 20
})

const tableData: any = reactive({
  list: [],
  total: 0
})

const entityId = computed(() => {
  return String(route.params?.entityId as string)
})

const reconciliationTaskId = computed(() => {
  return String(route.params?.reconciliationTaskId)
})

const md5UserParams = computed(() => {
  return (userId: string) => {
    const md5UserId = userId.slice(0, 18)
    return md5(md5UserId)
  }
})

const activityContent = computed(() => {
  return (item: any) => {
    let content = ''
    switch (item.type) {
      case 'ADD_RECONCILIATION_MATCH_SET':
        content = t('log.addReconciliationMatchSet', {
          name: item.extra?.name
        })
        break
      case 'DELETE_RECONCILIATION_MATCH_SET':
        content = t('log.deleteReconciliationMatchSet', {
          name: item.extra?.name
        })
        break
      case 'EDIT_RECONCILIATION_MATCH_SET_DETAIL':
        content = t('log.editReconciliationMatchSetDetail', {
          name: item.extra?.name
        })
        break
      case 'EDIT_RECONCILIATION_MATCH_SET_FILTER':
        content = t('log.editReconciliationMatchSetFilter', {
          name: item.extra?.name
        })
        break
      case 'ADD_RECONCILIATION_MATCH_RULE':
        content = t('log.addReconciliationMatchSetRule', {
          ruleName: item.extra?.name,
          setNo: item.extra?.taskNo
        })
        break
      case 'EDIT_RECONCILIATION_MATCH_RULE':
        content = t('log.editReconciliationMatchSetRule', {
          ruleName: item.extra?.name,
          setNo: item.extra?.taskNo
        })
        break
      case 'EXECUTE_RECONCILIATION_MATCH_SET':
        content = t('log.executeReconciliationMatchSet', {
          name: item.extra?.name
        })
        break
      case 'DELETE_RECONCILIATION_MATCH_RECORD':
        content = t('log.deleteReconciliationMatchRecord', {
          name: item.extra?.matchNo
        })
        break
      case 'ADD_RECONCILIATION_MATCH_RECORD':
        content = t('log.addReconciliationMatchRecord', {
          name: item.extra?.name
        })
        break
      case 'ADD_RECONCILIATION_TASK':
        content = t('reconciliation.createReconciliationTaskTip', {
          name: item.extra?.name
        })
        break
      case 'UPDATE_RECONCILIATION_TASK':
        content = t('reconciliation.modifiedReconciliationTaskTip', {
          name: item.extra?.name
        })
        break
      case 'DELETE_RECONCILIATION_TASK':
        content = t('reconciliation.deletedReconciliationTaskTip', {
          name: item.extra?.name
        })
        break
      case 'ADD_RECONCILIATION_TASK_DATA_SET':
        content = t('reconciliation.addDateSetForReconciliationTaskTip', {
          dataName: item.extra.name,
          name: item.extra?.taskName || ''
        })
        break
      case 'UPDATE_RECONCILIATION_TASK_DATA_SET':
        content = t('reconciliation.modifiedDateSetForReconciliationTaskTip', {
          dataName: item.extra.name,
          name: item.extra?.taskName || ''
        })
        break
      case 'DELETE_RECONCILIATION_TASK_DATA_SET':
        content = t('reconciliation.deleteDateSetForReconciliationTaskTip', {
          dataName: item.extra.name,
          name: item.extra?.taskName || ''
        })
        break
      case 'EXECUTE_RECONCILIATION_RULE':
        content = t('reconciliation.automaticRuleExecutionTip', {
          name: item.extra?.taskName || ''
        })
        break
      case 'ADD_RECONCILIATION_MATCH':
        content = t('reconciliation.manuallyAddedMatchRecord', {
          name: item.extra?.matchNo
        })
        break
      case 'ADD_RECONCILIATION_EXPLAIN':
        content = t('reconciliation.explainedDateSetForReconciliationTaskTip', {
          dataName: item.extra.dataId,
          name: item.extra?.taskName
        })
        break
      case 'DELETE_RECONCILIATION_EXPLAIN':
        content = t('reconciliation.clearedExplanationForReconciliationTaskTip', {
          dataName: item.extra.dataId,
          name: item.extra?.taskName
        })
        break
      case 'ADD_RECONCILIATION_ADJUSTMENT':
        content = t('reconciliation.createJournalForReconciliationTaskTip', {
          journalName: item.extra.journalNoList,
          name: item.extra?.taskName
        })
        break
      case 'DELETE_RECONCILIATION_ADJUSTMENT':
        content = t('reconciliation.deleteJournalForReconciliationTaskTip', {
          journalName: item.extra.journalNoList,
          name: item.extra?.taskName
        })
        break
      default:
        break
    }
    return content
  }
})

const changeAvatarColor: any = (val: string) => {
  const lastStr = val.substr(val.length - 1, 1)
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(Number(lastStr))) {
    return changeAvatarColor(val.substr(0, val.length - 1))
  }
  return avatarColor[lastStr]
}

const fetchReconciliationSetHistory = async () => {
  try {
    tableLoading.value = true
    const { data } = await ReconciliationApi.getReconciliationSetLogList(
      entityId.value,
      reconciliationTaskId.value,
      historyParams
    )
    tableData.list = data.list
    tableData.total = data.total
    historyTableRef.value?.setScrollTop(0)
    historyTableRef.value?.setScrollLeft(0)
  } catch (error: any) {
    console.log(error)
    ElMessage.error(error.message)
  } finally {
    tableLoading.value = false
  }
}

const onChangePage = (page: number) => {
  historyParams.page = page
  fetchReconciliationSetHistory()
}

/**
 * @description: 分页器改变每页条数触发
 * @param {number} limit
 */
const onChangePageSize = (limit: number) => {
  historyParams.limit = limit
  fetchReconciliationSetHistory()
}

onMounted(async () => {
  loading.value = true
  await fetchReconciliationSetHistory()
  loading.value = false
})

onBeforeUnmount(() => {
  tableData.list = []
  tableData.total = 0
})
</script>
<style lang="scss">
.elv-reconciliation-history {
  width: v-bind('props.width');
  height: calc(100% - 128px);
  display: flex;
  flex-direction: column;
  position: relative;
}

.elv-reconciliation-history-table-container {
  flex: 1;
  min-height: 0;
  position: relative;

  .elv-reconciliation-history-table-box {
    width: 100%;
    height: calc(100% - 60px);
    position: relative;
  }
}

.elv-reconciliation-history__title {
  color: #838d95;
  font-family: 'Plus Jakarta Sans';
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  padding: 16px 20px;

  .is-skeleton {
    width: 54px;
    height: 15px;
  }
}

.elv-reconciliation-history-table {
  --el-table-border-color: #d4dce5;
  --el-table-border: 1px solid #edf0f3;
  width: 100%;
  box-sizing: border-box;
  border: 1px solid #d4dce6;
  border-right: 0px;
  border-left: 0px;
  border-bottom-width: 1px;

  .el-table__inner-wrapper {
    width: 100%;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  &.el-table--border {
    border-bottom-color: #edf0f3;

    &::after,
    &::before {
      width: 0;
      height: 0;
    }
  }

  .el-table__body-wrapper {
    height: 616px;

    .el-scrollbar__view {
      width: 100%;
      margin-bottom: 0px !important;
    }
  }

  .el-table__border-left-patch {
    width: 0;
  }

  .elv-reconciliation-history-table-header {
    background: #eef4fb;

    .elv-reconciliation-history-table-header__cell {
      background: #eef4fb;
      height: 36px;
      box-sizing: border-box;
      border-right-color: #e4e7eb;
      border-bottom-color: #ced7e0;

      &:last-child {
        border-right: 0px;
      }

      .cell {
        font-family: 'Plus Jakarta Sans';
        font-style: normal;
        font-weight: 600;
        font-size: 12px;
        line-height: 15px;
        color: #666d74;
        padding: 0 10px;
        display: flex;
        align-items: center;
        justify-items: center;
      }
    }
  }

  // .elv-reconciliation-history-table-row:last-child {
  //   .elv-reconciliation-history-table-row__cell {
  //     border-bottom: 0px;
  //   }
  // }

  .elv-reconciliation-history-table-row__cell {
    padding: 0;
    height: 42px;

    &:last-child {
      border-right: 0px;
    }

    .cell {
      padding: 0 10px;
      font-family: 'Plus Jakarta Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      color: #0e0f11;
      line-height: 16px;
    }

    .elv-reconciliation-history-table-row__cell-activity {
      font-weight: 500;
      word-break: break-word;
    }

    .elv-reconciliation-history-table-row__cell-member {
      display: flex;
      align-items: center;

      img {
        width: 20px;
        height: 20px;
        background: #f6f6f6;
        border-radius: 50%;
        display: block;
        margin-right: 8px;
      }
    }

    .elv-reconciliation-history-table-row__cell-date {
      display: flex;
      align-items: center;
      font-family: 'Barlow';
      font-weight: 400;
      font-size: 13px;
      line-height: 16px;
      color: #0e0f11;
    }
  }
}

.elv-reconciliation-history-popper {
  .el-select-dropdown__list {
    padding: 0;
  }

  .el-select-dropdown__item {
    height: 40px;
    display: flex;
    align-items: center;
    padding-left: 12px;
    font-family: 'Plus Jakarta Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
    color: #0e0f11;

    img {
      width: 20px;
      height: 20px;
      display: block;
      margin-right: 10px;
    }

    p {
      display: flex;
      align-items: center;
      justify-content: center;

      span {
        width: fit-content;
        height: 18px;
        display: flex;
        align-items: center;
        justify-content: center;
        box-sizing: border-box;
        padding: 2px 4px 2px 4px;
        border-radius: 3px;
        border: 1px solid #dde1e6;
        margin-left: 10px;
        font-size: 11px;
        color: #d0d4d9;
      }
    }

    &.hover {
      background: #f9fafb;
    }
  }
}
</style>
